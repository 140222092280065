<template>
    <div class="order_container" v-loading="loading">
        <!-- 面包屑导航区域 -->
        <div style="margin-bottom: 20px;margin-left:20px;margin-top: 20px;">
            <el-row>
                <el-col :span="24" align="left">
                    <el-button size="mini" type="primary" @click="goback">返回</el-button>
                </el-col>



            </el-row>
        </div>

        <!-- 卡片试图区域 -->
        <div class="box">
            <!-- 左侧内容 -->
            <div style="width:400px;margin-right:0px;padding:10px 30px">
                <div class="box_left">
                    <h3 style="margin:0;">合同详情</h3>
                    <ul style="padding:0;margin:0;">
                        <li>
                            <!-- 标题 -->
                            <h3 style="margin:0;">{{ this.orderInfo.title }}</h3>
                        </li>

                        <!-- 类别 -->
                        <li>
                            <span style="font-size: 14px;color: #262626; line-height: 30px; ">合同类别：</span>
                            <span style="font-size: 14px;color: #a2a2a2;line-height: 30px; ">{{
                                    this.orderInfo.contractType
                            }}</span>
                        </li>
                        <!-- 编号 -->
                        <li>
                            <span style="font-size: 14px;color: #262626; line-height: 30px;">合同编号：</span>
                            <span style="font-size: 14px;color: #a2a2a2;line-height: 30px;">{{ this.orderInfo.contractNo
                            }}</span>
                        </li>
                        <!-- 签订时间 -->
                        <li>
                            <span style="font-size: 14px;color: #262626; line-height: 30px;">签订时间：</span>
                            <span style="font-size: 14px;color: #a2a2a2;">{{ this.orderInfo.signDate }}</span>
                        </li>

                        <!-- 签订人 -->
                        <li>
                            <span style="font-size: 14px;color: #262626; line-height: 30px;">签订人：</span>
                            <span style="font-size: 14px;color: #a2a2a2;line-height: 30px;">{{
                                    this.orderInfo.signPerson
                            }}</span>
                        </li>
                        <!-- 签订人类型 -->
                        <li>
                            <span style="font-size: 14px;color: #262626; line-height: 30px;">签订人类型：</span>
                            <span style="font-size: 14px;color: #a2a2a2; line-height: 30px;">{{
                                    this.orderInfo.signPerson
                            }}</span>
                        </li>
                        <!-- 联系电话 -->
                        <li>
                            <span style="font-size: 14px;color: #262626; line-height: 30px;">联系电话：</span>
                            <span style="font-size: 14px;color: #a2a2a2;line-height: 30px;">{{
                                    this.orderInfo.tel
                            }}</span>
                        </li>
                        <!-- 签订地址 -->
                        <li>
                            <span style="font-size: 14px;color: #262626; line-height: 30px;">签订地址：</span>
                            <span style="font-size: 14px;color: #a2a2a2;line-height: 30px;">{{ this.orderInfo.address
                            }}</span>
                        </li>
                        <!-- 所属乡镇 -->
                        <li>
                            <span style="font-size: 14px;color: #262626; line-height: 30px;">所属乡镇：</span>
                            <span style="font-size: 14px;color: #a2a2a2;line-height: 30px;">{{ this.orderInfo.town
                            }}</span>
                        </li>
                        <!-- 合同订单数量 -->
                        <li>
                            <span style="font-size: 14px;color: #262626; line-height: 30px;">合同订单数量:</span>
                            <span style="font-size: 14px;color: #a2a2a2;line-height: 30px; padding-left: 5px;">{{
                                    this.orderInfo.orderNum
                            }}</span>
                        </li>
                        <!-- 合同订单价格 -->
                        <li>
                            <span style="font-size: 14px;color: #262626; line-height: 30px;">合同订单价格:</span>
                            <span style="font-size: 14px;color: #a2a2a2;line-height: 30px;  padding-left: 5px;">{{
                                    this.orderInfo.contractPrice
                            }}</span>
                        </li>

                        <!-- 现场照片 -->
                        <li>
                            <p style="font-size: 14px;color: #262626; line-height: 30px;">合同：</p>
                            <div style="width:100px;height:100px;float:left;margin:2px;margin-top: 20px;"
                                v-for="(item, index) in  this.orderInfo.fids" :key="index">
                                <el-image style="width:100%;height:100%" :src="img + item"
                                    :preview-src-list="[img + item]"></el-image>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 右侧内容 -->
            <div style="width:100%;padding:10px 20px">
                <div class="dd">合同审核状态</div>

                <!-- 待提交 -->
                <!-- <div    v-if="this.orderInfo.status == 100" style="
                display: flex;
height: 28px;
font-size: 15px;
font-weight: 550;
color: rgba(0,0,0,0.85);
line-height: 28px;">合同当前状态: <span  style="padding-left:7px;font-weight: 500;
color: #D0021B;">请先提交合同</span>       </div> -->



                <!-- 审核状态    第一个通过 第二个驳回-->
                <div>
                    <div v-if="orderInfo.status == 203" style=" margin-bottom: 19px;
display: flex;
height: 28px;
font-size: 15px;
font-weight: 550;
color: rgba(0,0,0,0.85);
line-height: 28px;">合同当前状态: <span style="padding-left:7px;font-weight: 500;
color: #D0021B;">{{ '驳回' }}</span>
                    </div>
                    <div v-if="orderInfo.status == 201" style=" margin-bottom: 19px;
                display: flex;
height: 28px;
font-size: 15px;
font-weight: 550;
color: rgba(0,0,0,0.85);
line-height: 28px;">合同当前状态: <span style="padding-left:7px;font-weight: 500;
color: #F5A623;">{{ '审核中' }}</span>
                    </div>
                    <div v-if="orderInfo.status == 202 || orderInfo.status == 300" style=" margin-bottom: 19px;
                display: flex;
height: 28px;
font-size: 15px;
font-weight: 550;
color: rgba(0,0,0,0.85);
line-height: 28px;">合同当前状态: <span style="padding-left:7px;font-weight: 500;
color: #60B572;">{{ '已通过 ' }}</span>
                    </div>
                    <div class="sh" style="display:flex; align-items: center;justify-content: space-between ">
                        <div class="resulet" v-for="(item, index) in  this.list" :key="index">
                            <div v-if="item.status == 202 && item.examDate"><span>处理结果:</span> <span
                                    style="color: #60B572;">审批通过</span>
                            </div>
                            <div v-if="item.status == 203 && item.examDate"><span>处理结果:</span> <span style="
color: #D0021B;">驳回</span></div>
                            <div v-if="item.status == 201"><span>处理结果:</span> <span style="color: #F5A623;">审批中</span>
                            </div>
                            <!-- <div v-if="item.stage == 2 && item.status == 200"><span>处理结果:</span> <span
                                    style="color: #F5A623;">待审核</span>
                            </div> -->
                            <!-- <div v-if="item.stage == 1 && item.status == 200 && item.examDate"><span>处理结果:</span> <span
                                    style="color: #F5A623;">待审核</span>
                            </div> -->
                            <div v-if="role && item.stage == 2 && item.status == 200"><span>处理结果:</span> <span
                                    style="color: #F5A623;">待审核</span>
                            </div>
                            <div v-if="item.stage == 2 && item.status == 201"><span>处理结果:</span> <span
                                    style="color: #F5A623;">审核中</span>
                            </div>
                            <div v-if="item.stage == 1 && item.status == 201 && item.examDate"><span>处理结果:</span> <span
                                    style="color: #F5A623;">审核中</span>
                            </div>
                            <div v-if="item.stage == 1 && item.examDate" style="margin-top:20px"> <span>处理部门:</span>
                                <span>农畜牧业局</span>
                            </div>
                            <div v-if="item.stage == 2 && item.status == 202" style="margin-top:20px">
                                <span>处理部门:</span>
                                <span>市场监督管理局</span>
                            </div>
                            <div v-if="role && item.stage == 2 && item.status == 200" style="margin-top:20px">
                                <span>处理部门:</span>
                                <span>市场监督管理局</span>
                            </div>

                            <div v-if="item.stage == 2 && item.status == 203" style="margin-top:20px">
                                <span>处理部门:</span>
                                <span>市场监督管理局</span>
                            </div>
                            <div v-if="item.examDate" style="margin-top:20px"> <span>处理时间:</span> <span>{{ item.examDate
                            }}</span></div>
                            <div v-if="item.suggestion" style=" width:320px; margin-top:20px; white-space: normal; 
    word-break:break-all;"> <span>处理意见:</span> <span>{{ item.suggestion }}</span></div>
                        </div>



                    </div>
                    <div v-if="status == 200" class="dd" style="margin-top:49px">请选择合同审核意见</div>
                    <div v-if="status == 200">
                        <el-radio-group v-model="reviewContractDto.status">
                            <el-radio v-model="reviewContractDto.status" @change="change" :label=202>审核通过</el-radio>
                            <el-radio v-model="reviewContractDto.status" @change="change" :label=203>合同驳回</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="status == 200" class="dd" style="margin-top:40px">请输入意见</div>

                    <el-input v-if="status == 200" type="textarea" placeholder="请输入" style="width:594px"
                        :autosize="{ minRows: 2, maxRows: 4 }" v-model="reviewContractDto.suggestion"></el-input>


                    <div v-if="status == 200" style="width: 124px; margin-top: 30px;
height: 40px;
background: #1890FF;
height: 40px;
 font-size: 14px;
color: #FFFFFF;
line-height: 40px;text-align: center;" @click="serch">提交
                    </div>
                </div>


            </div>
        </div>




    </div>
</template>
  
<script>

import {
    getcontractInfoDetail,
    reviewContract,
    reviewResult


} from "../../request/http";
export default {
    data() {
        return {
            dialogVisible: false,
            loading: true,//加载动画
            contractId: "", //合同id
            orderInfo: {}, //合同详情信息
            status: null,
            role: null,
            stage: null,
            list: [],
            reviewContractDto: {
                recordId: null,
                status: null,
                suggestion: null
            }

        };
    },
    created() {
        this.role = localStorage.getItem('role')
        if (localStorage.getItem('role') == 3) {
            this.role = true
        }
        if (localStorage.getItem('role') == 4) {
            this.role = false
        }
        console.log(this.role);
        this.contractId = this.$route.query.contractId;

        this.reviewContractDto.recordId = Number(this.$route.query.recordId)
        if (this.$route.query.stage == 1) {
            this.stage = '农牧业局'
        }
        if (this.$route.query.stage == 2) {
            this.stage = '市场监管局'
        }
        this.status = this.$route.query.status;
        console.log(this.contractId);
        this.getcontractInfoDetail(); // 获取订单详情
        this.reviewResult()


    },
    computed: {},

    methods: {
        //获取审核结果
        async reviewResult() {

            const { data } = await reviewResult(this.contractId);

            this.list = data.data
            console.log(this.list);

            if (data.code !== 0) {
                return this.$message.error(data.msg);
            }




        },
        change(val) {
            console.log(val);
            this.reviewContractDto.status = Number(val)
        },
        async serch() {
            console.log(this.reviewContractDto);
            let { data } = await reviewContract(this.reviewContractDto);
            if (data.code == 0) {
                this.$message({
                    type: "success",
                    message: "设置成功",
                });
            }
            else if (data.code !== 0) {
                this.$message({
                    type: "error",
                    message: data.msg,
                })
            }
            this.goback()
        },
        goback() {
            this.$router.go(-1)
        },

        // 获取订单详情
        async getcontractInfoDetail() {
            const { data } = await getcontractInfoDetail(this.contractId);

            let list = data.data
            if (data.code !== 0) {
                return this.$message.error(data.msg);
            }
            this.orderInfo = list;
            console.log(this.orderInfo);
            this.loading = false



        },












    }
};
</script>
  
<style lang="scss" scoped>
.order_container {
    .resulet {
        display: flex;

        flex-direction: column;
        justify-content: center;

    }

    .sh {
        &::after {
            content: "";
            width: 272px;
        }
    }

    .box {
        display: flex;

        .box_left {
            ul li {
                list-style: none;
                margin-top: 20px;


            }
        }

        .box_right {
            .el-button {
                width: 112px;
                height: 40px;
                color: #d0021bff;
                background: rgba(208, 2, 27, 0.05);
                border-radius: 4px;
                border: 1px solid #d0021b;
                margin-left: 20px;
            }

            .select_box {
                margin-left: 35px;
            }

            .el-select {
                width: 200px;
                margin-right: 10px;
            }

            .btn {
                width: 80px;
                height: 32px;
                color: #ffffff;
                background: #007afc;
                border-radius: 4px;
                border: 1px solid #007afc;
            }

            .ipt {
                width: 200px;
                height: 40px;
                margin-right: 10px;
            }

            .btn_one {
                width: 60px;
                height: 30px;
                color: #ffffff;
                background: #007afc;
                border-radius: 4px;
                border: 1px solid #007afc;
            }

            .bigBox {
                display: flex;
                justify-content: space-between;

                &::after {
                    content: "";
                    width: 1px;
                }

                .contain {
                    width: 100%;
                    padding: 0 10px 0 10px;
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
                    border-radius: 8px;
                    border: 1px solid #e9e9e9;

                    // margin: auto;
                    .one {
                        display: flex;
                        justify-content: space-between;
                        height: 50px;
                        line-height: 50px;
                        border-bottom: 1px solid #dededeff;

                        .left {
                            font-size: 14px;
                            font-family: PingFang-SC-Medium, PingFang-SC;
                            color: rgba(0, 0, 0, 0.85);
                        }

                        .right {
                            width: 250px;
                            font-size: 14px;
                            font-family: PingFang-SC-Regular, PingFang-SC;
                            color: rgba(0, 0, 0, 0.85);
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-align: right;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .div__body {
            padding: 0;
            margin: 0;
        }

        .footer {
            display: flex;
            margin-top: 80px;

            .footer_left {
                width: 50%;

                h5 {
                    margin: 0;
                }
            }

            .footer_right {
                width: 70%;

                h5 {
                    margin: 0;
                }
            }
        }
    }

    li {
        list-style: none;
    }

    .btn {
        width: 80px;
        height: 32px;
        color: #ffffff;
        background: #007afc;
        border-radius: 4px;
        border: 1px solid #007afc;
    }

    .ipt {
        width: 200px;
        height: 40px;
        margin-right: 10px;
    }

    .el-select {
        width: 200px;
        margin-right: 10px;
    }
}

.dd {
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 600;
}

.big {}

.stitle {
    margin-bottom: 20px;
    font-size: 14px 600
}

.ph {
    margin-bottom: 20px;
}
</style>
  
  
  
   
            